var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "8px",
      "padding-block": ['10px', '12px'],
      "padding-left": ['10px', '12px'],
      "padding-right": ['10px', _vm.ribbonText ? '30px' : '12px'],
      "border-radius": ['8px', '0'],
      "background-color": "rgba(0, 140, 129, 0.20)",
      "position": "relative"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['block', 'flex'],
      "align-items": "center"
    }
  }, [_c('c-box', {
    staticClass: "product-photo",
    attrs: {
      "width": "100%",
      "height": "100%",
      "margin-right": ['10px', '16px'],
      "float": "left"
    }
  }, [!!_vm.productPhotoSrc ? _c('c-image', {
    attrs: {
      "src": _vm.productPhotoSrc,
      "border-radius": "12px",
      "object-fit": "cover"
    }
  }) : _vm._e()], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '28px'],
      "font-weight": [500, 700],
      "mb": ['0px', '10px'],
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.productName) + " ")]), _vm.isEnterprise ? _c('c-box', {
    staticClass: "for-enterprise",
    attrs: {
      "mb": "1rem",
      "text-align": "justify"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.productDescription)
    }
  }) : _c('c-box', {
    staticClass: "overview",
    attrs: {
      "font-family": "Roboto",
      "font-size": ['11px', '16px'],
      "mb": ['0.5rem', '1rem'],
      "text-align": "justify"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.productDescription)
    }
  }), _c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": ['12px', '14px'],
      "margin-top": "8px"
    }
  }, [_vm._v(" Masih bingung terkait " + _vm._s(_vm.productName) + "? "), !_vm.isEnterprise ? _c('c-box', {
    attrs: {
      "d": "inline-flex",
      "align-items": "center",
      "color": "#008C81",
      "cursor": "pointer",
      "_hover": {
        textDecoration: 'underline'
      }
    },
    on: {
      "click": _vm.onClickLearnMore
    }
  }, [_vm._v(" Pelajari Lebih Lanjut "), _c('c-image', {
    attrs: {
      "d": "inline",
      "src": require('@/assets/icons/icon-info.svg'),
      "w": ['12px', '14px'],
      "h": ['12px', '14px'],
      "ml": "4px"
    }
  })], 1) : _vm._e()], 1), _vm.isEnterprise ? _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: '#005A64 !important'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: '#005A64 !important',\n          },\n        }"
    }],
    attrs: {
      "border-radius": ['24px', '32px'],
      "px": ['32px', '48px'],
      "py": ['8px', '16px'],
      "background-color": "#008C81",
      "color": "#FFFFFF",
      "font-size": ['14px', '16px'],
      "font-weight": "500",
      "font-family": "Roboto",
      "as": "router-link",
      "to": {
        name: 'client.enterpriseService'
      }
    }
  }, [_vm._v(" Pelajari Lebih Lanjut ")]) : _vm._e()], 1)], 1), _c('c-box', {
    attrs: {
      "display": ['flex', 'none'],
      "font-family": "Roboto",
      "font-size": ['10px', '14px'],
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_vm._v(" Masih bingung terkait " + _vm._s(_vm.productName) + "? "), !_vm.isEnterprise ? _c('c-box', {
    attrs: {
      "margin-left": "auto",
      "d": "inline-flex",
      "align-items": "center",
      "color": "#008C81",
      "flex-shrink": "0",
      "cursor": "pointer",
      "_hover": {
        textDecoration: 'underline'
      }
    },
    on: {
      "click": _vm.onClickLearnMore
    }
  }, [_vm._v(" Pelajari Lebih Lanjut "), _c('c-image', {
    attrs: {
      "d": "inline",
      "src": require('@/assets/icons/icon-info.svg'),
      "w": ['10px', '14px'],
      "h": ['10px', '14px'],
      "ml": "4px"
    }
  })], 1) : _vm._e()], 1), _vm.ribbonText ? _c('c-box', {
    staticClass: "ribbon"
  }, [_vm._v(" " + _vm._s(_vm.ribbonText) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }