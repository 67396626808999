<template>
  <c-flex
    flex-direction="column"
    gap="8px"
    :padding-block="['10px', '12px']"
    :padding-left="['10px', '12px']"
    :padding-right="['10px', ribbonText ? '30px' : '12px']"
    :border-radius="['8px', '0']"
    background-color="rgba(0, 140, 129, 0.20)"
    position="relative"
  >
    <c-box
      :display="['block', 'flex']"
      align-items="center"
    >
      <c-box
        width="100%"
        height="100%"
        :margin-right="['10px', '16px']"
        float="left"
        class="product-photo"
      >
        <c-image
          v-if="!!productPhotoSrc"
          :src="productPhotoSrc"
          border-radius="12px"
          object-fit="cover"
        />
      </c-box>
      <c-box>
        <c-text
          font-family="Roboto"
          :font-size="['18px', '28px']"
          :font-weight="[500, 700]"
          :mb="['0px', '10px']"
          color="primary.400"
        >
          {{ productName }}
        </c-text>
        <c-box
          v-if="isEnterprise"
          class="for-enterprise"
          mb="1rem"
          text-align="justify"
          v-html="productDescription"
        />
        <c-box
          v-else
          font-family="Roboto"
          :font-size="['11px', '16px']"
          :mb="['0.5rem', '1rem']"
          text-align="justify"
          class="overview"
          v-html="productDescription"
        />
        <c-box
          :display="['none', 'block']"
          font-family="Roboto"
          :font-size="['12px', '14px']"
          margin-top="8px"
        >
          Masih bingung terkait {{ productName }}?
          <c-box
            v-if="!isEnterprise"
            d="inline-flex"
            align-items="center"
            color="#008C81"
            cursor="pointer"
            :_hover="{
              textDecoration: 'underline'
            }"
            @click="onClickLearnMore"
          >
            Pelajari Lebih Lanjut
            <c-image
              d="inline"
              :src="require('@/assets/icons/icon-info.svg')"
              :w="['12px', '14px']"
              :h="['12px', '14px']"
              ml="4px"
            />
          </c-box>
        </c-box>
        <c-button
          v-if="isEnterprise"
          v-chakra="{
            ':hover': {
              backgroundColor: '#005A64 !important',
            },
          }"
          :border-radius="['24px', '32px']"
          :px="['32px', '48px']"
          :py="['8px', '16px']"
          background-color="#008C81"
          color="#FFFFFF"
          :font-size="['14px', '16px']"
          font-weight="500"
          font-family="Roboto"
          as="router-link"
          :to="{ name: 'client.enterpriseService' }"
        >
          Pelajari Lebih Lanjut
        </c-button>
      </c-box>
    </c-box>
    <c-box
      :display="['flex', 'none']"
      font-family="Roboto"
      :font-size="['10px', '14px']"
      justify-content="space-between"
      align-items="center"
    >
      Masih bingung terkait {{ productName }}?
      <c-box
        v-if="!isEnterprise"
        margin-left="auto"
        d="inline-flex"
        align-items="center"
        color="#008C81"
        flex-shrink="0"
        cursor="pointer"
        :_hover="{
          textDecoration: 'underline'
        }"
        @click="onClickLearnMore"
      >
        Pelajari Lebih Lanjut
        <c-image
          d="inline"
          :src="require('@/assets/icons/icon-info.svg')"
          :w="['10px', '14px']"
          :h="['10px', '14px']"
          ml="4px"
        />
      </c-box>
    </c-box>
    <c-box
      v-if="ribbonText"
      class="ribbon"
    >
      {{ ribbonText }}
    </c-box>
  </c-flex>
</template>

<script>
import { CBox, CFlex, CImage, CButton, CText } from '@chakra-ui/vue'

export default {
  name: 'CardSimpleDetailProgram',
  components: {
    CBox,
    CFlex,
    CImage,
    CButton,
    CText,
  },
  props: {
    productPhotoSrc: {
      required: true,
      type: String,
      default: '',
    },
    productName: {
      required: true,
      type: String,
      default: '',
    },
    productDescription: {
      required: true,
      type: String,
      default: '',
    },
    productSlug: {
      required: true,
      type: String,
      default: '',
    },
    productId: {
      required: true,
      type: String,
      default: '',
    },
    isEnterprise: {
      type: Boolean,
      default: false,
    },
    ribbonText: {
      type: String,
      default: '',
    },
    customUrl: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickLearnMore() {
      if (this.customUrl) {
        return window.open(this.customUrl, '_self')
      }
      this.$router.push({
        name: 'client.program.detail',
        params: {
          id: this.productSlug ? this.productSlug : this.productId,
        },
      })
    },
  },
}
</script>

<style scoped>
.overview {
  display: inline;
  list-style-position: inside;
}

/* SOURCE REF: https://css-generators.com/ribbon-shapes/ */
.ribbon {
  font-size: 8px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --f: .5em; /* control the folded part */
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51%/var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((1 - cos(45deg))*100%), -100%) rotate(45deg);
  transform-origin: 0% 100%;
  background-color: #D32737; /* the main color  */
}
@media (min-width: 768px) {
  .ribbon {
    font-size: 14px;
  }
}

/* Product Photo - START */
.product-photo {
  max-width: 116px;
  max-height: 128px;
}

.product-photo img {
  width: 116px;
  height: 128px;
}

@media (max-width: 768px) {
  .product-photo {
    max-width: 90px;
    max-height: 110px;
  }
  
  .product-photo img {
    width: 90px;
    height: 110px;
  }
}

@media (max-width: 453px) {
  .product-photo {
    max-width: 70px;
    max-height: 75px;
  }
  
  .product-photo img {
    width: 70px;
    height: 75px;
  }
}

@media (max-width: 415px) {
  .product-photo {
    max-width: 90px;
    max-height: 90px;
  }

  .product-photo img {
    width: 90px;
    height: 90px;
  }
}
/* Product Photo - END */
</style>